<script setup>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()

try {
  await store.dispatch('ajax', {
    path: `/oauth/link/${route.params.provider}`,
    data: {
      authCode: route.query.code
    }
  })
} catch (e) {
  await store.dispatch('alert', {
    error: true,
    message: 'An error occurred linking your account. Please try again or contact support'
  })
  console.debug(e)
}

router.push({ name: 'Profile' })
</script>

<template>
  <div>
    <font-awesome-icon icon="spinner" spin fixed-width size="2xl" />
  </div>
</template>

<style scoped lang="scss"></style>
